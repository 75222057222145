import { config } from "../helpers/domain-config";
import { webstore } from "../helpers/auth-helpers";
import { getRockmanId } from "../helpers/auth-helpers";
import axios from "axios";

/* eslint-disable max-len */
const UnsubLinks = {
  OM: "https://n.xr-academy.com/unsubscribe/om?country=om&domain=om.xr-academy.com",
  ZA: "https://n.xr-academy.com/unsubscribe/za/?country=za&domain=za.xr-academy.com",
  DE: "https://n.xr-academy.com/unsubscribe/de/?country=de&domain=de.xr-academy.com",
  CA: "https://n.mobiovr.com/unsubscribe/ca/?country=ca&domain=ca.mobiovr.com&platform=mcb",
  CZ: "https://n.xr-academy.com/unsubscribe/cz/?lang=cz",
  MY: "https://checksubscription.com/?c=my",
  SG: "https://checksubscription.com/?c=sg",
  QA: "https://n.xr-academy.com/unsubscribe/qa?lang=en",
  PS: "https://n.xr-academy.com/unsubscribe/ps?lang=ar",
  ES: "https://emocion.fonestarz.com/micuentamovil/",
  PK: "https://checksubscription.com/?c=pk",
  TH: "https://checksubscription.com/?c=th",
  ID: {
    PKG1: "http://m.xr-academy.com/unsubscribe/id",
    PKG2: "http://m2.xr-academy.com/unsubscribe/id",
  },
};
/* eslint-enable max-len */

export const getUnsubLink = (packageKey) => {
  const domain = config.subdomain.toUpperCase();
  const uid = webstore.getProperty("token");
  if (Object.keys(UnsubLinks).indexOf(domain) >= 0) {
    const appendToken = domain !== "ES";

    if (domain === "ID" && packageKey) {
      return UnsubLinks[domain][packageKey];
    } else if ((domain !== "MY" || domain !== "PK") && uid && appendToken) {
      return `${UnsubLinks[domain]}&token=${uid}`;
    } else {
      return UnsubLinks[domain];
    }
  }
  return null;
};

export const UnsubscribePE = async () => {
  const rockmanId = getRockmanId();

  try {
    const response = await axios.get(
      "/api/unsubscribe?country=pe&token=" + rockmanId
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
