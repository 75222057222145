import { disposeAsset, getAsset } from "../utils/assets.utils";
import { Endpoints } from "../../endpoints";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import EnterArButton from "../../vue/components/enter-ar-button.component.vue";
import VideoMenuPreviousNextButtons from "../../vue/components/previous-next-buttons.component.vue";
import { disposeObject3D, waitForLoaderToHide } from "../utils/scene.utils";
import { getState, transitionTo } from "../../vue/utils/aframe.utils";
import { getOrbitControls, resetOrbitalCamera } from "../utils/camera.utils";
import {
  sendOnModelLoad,
  sendOnAnnotationClick,
} from "../../vue/utils/dataCollection.utils";
import { errorHandler } from "../utils/error-handler.utils";

const MAX_DISTANCE = 100;

function verifyIfModelIsValid(object3D) {
  const { children, material } = object3D;

  if (material) {
    const data = JSON.parse(JSON.stringify(material));
    if (data && data.images && data.images[0]) {
      const { url } = data.images[0];
      if (!url) {
        getState().debug &&
          console.error("Error while loading model. Missing texture.");
        return false;
      }
    }
  }

  for (let i = 0; i < children.length; i++) {
    if (!verifyIfModelIsValid(children[i])) {
      return false;
    }
  }

  return true;
}

AFRAME.registerComponent("model-scene-controller", {
  events: {
    sceneEnter: async function (e) {
      const { category, submenu, id } = e.detail.routeParameters;

      this.firstScene = e.detail.firstScene;
      this.id = id;

      this.el.sceneEl.emit("showLoaderText");
      this.el.sceneEl.emit("showLoader");

      this.modelLoaded = false;
      this.el.sceneEl.emit("activateLookCamera");

      const model = await this.el.sceneEl.systems["data"].fetch(
        Endpoints.models(id, category)
      );

      const { annotations, arCoreSrc, backScene, src, transform } = model;

      if (getState().arMode) {
        this.arButton = addVueComponent(EnterArButton, {
          href: arCoreSrc || src,
          object3D: this.elModel.object3D,
        });
      }

      this.vueButtons = addVueComponent(VideoMenuPreviousNextButtons);
      this.vueButtons.addEventListener("buttonPressed", this.selectAnnotation);
      this.elHeader.setAttribute("generic-menu-header", {
        asset: id,
        category,
        enabled: true,
        submenu,
      });

      if (getState().isIOS) {
        const asset = await getAsset(src, { showLoader: true });
        const selector = `#${asset.getAttribute("id")}`;
        this.elModel.setAttribute("app-gltf-model", {
          src: selector,
          size: 512,
        });
      } else {
        this.elModel.setAttribute("gltf-model", src);
      }
      this.el.sceneEl.systems["loader"].addIdForLoader(
        "scene-model.loading-model"
      );

      const { position, rotation, scale } = transform;
      this.backScene = backScene;
      this.elContainer.setAttribute("position", position);

      const pivotWorldPosition = new THREE.Vector3();
      this.elModel.object3D.getWorldPosition(pivotWorldPosition);
      const pivot = {
        x: pivotWorldPosition.x,
        y: pivotWorldPosition.y,
        z: pivotWorldPosition.z + 5,
      };
      this.elModel.setAttribute("position", pivot);
      this.elModel.setAttribute("rotation", rotation);
      this.elModel.setAttribute("scale", scale);
      this.elModelPivot.setAttribute("position", {
        x: -pivot.x,
        y: -pivot.y,
        z: -pivot.z,
      });

      this.elAnnotations.setAttribute("position", {
        x: annotations.position.x + pivot.x,
        y: annotations.position.y + pivot.y,
        z: annotations.position.z + pivot.z,
      });
      this.elAnnotations.setAttribute("rotation", annotations.rotation);
      this.elAnnotations.setAttribute("scale", annotations.scale);
      annotations.nodes.forEach((annotation, index) => {
        const entity = document.createElement("a-entity");
        entity.setAttribute("data-content", index + 1);
        entity.setAttribute("data-renderorder", index * 10);
        entity.setAttribute(
          "data-scale",
          `${annotations.scale.x} ${annotations.scale.y} ${annotations.scale.z}`
        );
        entity.setAttribute("look-at", ".model");
        entity.setAttribute("position", annotation.position);
        entity.setAttribute("template", "src", "#markerButtonTemplate");
        entity.addEventListener("click", () => {
          this.onAnnotationClick(annotation);
          sendOnAnnotationClick({
            contentName: id,
            contentCategory: submenu,
            annotationId: index + 1,
            trigger: "click",
          });
        });
        this.elAnnotations.appendChild(entity);
      });

      const target = { x: 0, y: 0, z: -5 };
      this.el.sceneEl.emit("activateOrbitalCamera", {
        ...model.camera,
        maxDistance: 100,
        target,
        // panSpeed: 0.1,
        rotateSpeed: 1,
      });
      this.rotateCamera(0);
      this.setupVrCamera({ x: 0, y: 0, z: -5 }, 5);

      this.annotations = annotations.nodes;
      this.modelPosition = { ...position };

      if (!this.modelLoaded) {
        this.el.sceneEl.emit("showLoader");
        await this.waitForModelToLoad();
      }

      if (getState().debug) {
        this.elModel.setAttribute("data-raycastable", "");
        this.elModel.setAttribute("model-annotation-helper", "");
      }

      waitForLoaderToHide();
      this.el.sceneEl.emit("hideLoader");

      // send model load event
      sendOnModelLoad({ contentName: id, contentCategory: submenu });
    },

    sceneExiting: function () {
      if (this.elModel.getAttribute("app-gltf-model")) {
        disposeAsset(this.elModel.getAttribute("app-gltf-model").src);
      }

      if (this.elModel.getAttribute("gltf-model")) {
        disposeAsset(this.elModel.getAttribute("gltf-model"));
      }
      disposeObject3D(this.elModel.object3D);

      this.elModel.removeAttribute("app-gltf-model");
      this.elModel.removeAttribute("gltf-model");
      this.removeDialogBox();

      if (getState().debug) {
        this.elModel.removeAttribute("data-raycastable");
        this.elModel.removeAttribute("model-annotation-helper");
      }

      Array.from(this.elAnnotations.children).forEach((elAnnotation) => {
        disposeObject3D(elAnnotation.object3D);
        elAnnotation.parentElement.removeChild(elAnnotation);
      });
      removeVueComponent(this.vueButtons);
    },

    sceneExit: function () {
      if (!getState().userIsInVR) {
        resetOrbitalCamera();
      }

      if (this.arButton) {
        removeVueComponent(this.arButton);
        this.arButton = null;
      }

      this.elHeader.setAttribute("generic-menu-header", "enabled", false);
      this.el.sceneEl.emit("clearSceneInformation");
      this.el.sceneEl.emit("resetVRCameraPosition");
      this.elContainer.setAttribute("rotation", {
        x: 0,
        y: 0,
        z: 0,
      });
    },

    closeDialogBox: function () {
      this.removeDialogBox();
    },

    goBack: function () {
      transitionTo(this.backScene.split("/"));
    },

    nextAnnotation: function () {
      this.selectAnnotation({ detail: 1 });
    },

    previousAnnotation: function () {
      this.selectAnnotation({ detail: -1 });
    },
  },

  init: function () {
    this.elAnnotations = this.el.querySelector(".annotations");
    this.elCamera = getOrbitControls();
    this.elContainer = document.querySelector(".container");
    this.elHeader = document.querySelector(".header");
    this.elModel = this.el.querySelector(".model");
    this.elModelPivot = this.el.querySelector(".model-pivot");

    this.annotations = [];
    this.currentAnnotation = null;
    this.elDialogBox = null;
    this.modelLoaded = false;
    this.modelPosition = null;

    this.onModelLoaded = this.onModelLoaded.bind(this);
    this.removeDialogBox = this.removeDialogBox.bind(this);
    this.selectAnnotation = this.selectAnnotation.bind(this);
    this.waitForModelToLoad = this.waitForModelToLoad.bind(this);

    this.elModel.addEventListener("model-loaded", this.onModelLoaded);

    this.el.sceneEl.addEventListener("enter-vr", () => {
      this.elContainer.setAttribute("rotation", "0 0 0");
    });
  },

  onAnnotationClick: function (annotation) {
    if (this.currentAnnotation === annotation) {
      this.showCurrentDialogBox();
      return;
    }

    this.currentAnnotation = annotation;
    this.showCurrentAnnotation();
  },

  onModelLoaded: function () {
    const { debug, isIOS } = getState();
    if (!verifyIfModelIsValid(this.elModel.object3D) && !debug) {
      if (!this.firstScene && isIOS) {
        window.location.reload();
        return;
      } else {
        errorHandler(
          {},
          `Error while loading model ${this.id}. Missing texture`,
          "3D Mode"
        );
      }

      return;
    }

    this.el.sceneEl.systems["loader"].removeIdForLoader(
      "scene-model.loading-model"
    );
    this.modelLoaded = true;
  },

  removeDialogBox: function () {
    if (!this.elDialogBox) {
      return;
    }

    this.elAnnotations.removeChild(this.elDialogBox);
    this.elDialogBox = null;
  },

  rotateCamera: function (rotation) {
    if (getState().userIsInVR) {
      return;
    }

    const { controls } = getOrbitControls().components["orbit-controls"];
    let current = controls
      ? THREE.MathUtils.radToDeg(controls.getAzimuthalAngle())
      : 0;
    while (current < 0) {
      current += 360;
    }
    while (current > 360) {
      current -= 360;
    }

    const y = this.elContainer.object3D.position.y;
    let to = current - rotation;
    if (Math.abs(y - to) > 180) {
      to -= 360;
    }

    this.elContainer.setAttribute("animation__rotation", {
      property: "rotation",
      to: `0 ${to} 0`,
    });
  },

  selectAnnotation: function (e) {
    const move = e.detail;
    let index = this.annotations.findIndex(
      (search) => search === this.currentAnnotation
    );

    if (index === -1) {
      index = move < 0 ? this.annotations.length - 1 : 0;
    } else {
      index += move;
    }

    if (index < 0) {
      index = this.annotations.length - 1;
    } else if (index >= this.annotations.length) {
      index = 0;
    }

    this.currentAnnotation = this.annotations[index];
    this.showCurrentAnnotation();
  },

  showCurrentAnnotation: async function () {
    this.showCurrentDialogBox();

    const { animateCamera, position, rotation, vrZoom, zoom } =
      this.currentAnnotation;

    const dur = 2500;
    const modelPosition = this.modelPosition;
    const targetPosition = {
      x: modelPosition.x + position.x,
      y: modelPosition.y + position.y,
      z: modelPosition.z + position.z,
    };
    this.setupVrCamera(targetPosition, vrZoom || zoom + 1);

    if (zoom) {
      ["minDistance", "maxDistance"].forEach((property) => {
        const from =
          this.elCamera.object3D.position.distanceTo(targetPosition) * 1.4;
        this.elCamera.setAttribute(`animation__${property}`, {
          dur,
          from: property === "maxDistance" ? from : null,
          property: `orbit-controls.${property}`,
          to: zoom,
        });

        setTimeout(() => {
          this.elCamera.setAttribute(`animation__${property}`, {
            dur: 1,
            from: null,
            property: `orbit-controls.${property}`,
            to: property === "minDistance" ? 0 : MAX_DISTANCE,
          });
        }, dur + 400);
      });
    }

    if (animateCamera) {
      this.elCamera.setAttribute(`animation__target`, {
        dur,
        property: `orbit-controls.target`,
        to: targetPosition,
      });
    }

    if (rotation || rotation === 0) {
      this.rotateCamera(rotation);
    }
  },

  showCurrentDialogBox: function () {
    this.removeDialogBox();

    const { content, position, title } = this.currentAnnotation;
    const entity = document.createElement("a-entity");
    entity.setAttribute("data-content", content || "");
    entity.setAttribute("data-title", title);
    entity.setAttribute("position", {
      ...position,
      x: position.x,
      y: position.y,
    });
    entity.setAttribute("template", "src", "#dialogBoxTemplate");
    this.elDialogBox = entity;

    setTimeout(() => {
      this.elAnnotations.appendChild(entity);
    });
  },

  setupVrCamera: async function (targetPosition, cameraDistance) {
    if (!cameraDistance) {
      return;
    }

    const vrCameraDistance = cameraDistance || 1.5;
    const vrCameraPosition = {
      ...targetPosition,
      x: targetPosition.x,
      z: parseInt(targetPosition.z) + vrCameraDistance,
    };

    if (this.el.sceneEl.systems.state.state.userIsInVR) {
      await this.el.sceneEl.systems.transition.play(true);
      this.el.sceneEl.emit("setVRCameraPosition", vrCameraPosition);
      await this.el.sceneEl.systems.transition.play(false);
    } else {
      this.el.sceneEl.emit("setVRCameraPosition", vrCameraPosition);
    }
  },

  waitForModelToLoad: function () {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.modelLoaded) {
          return;
        }

        clearInterval(interval);
        resolve();
      }, 500);
    });
  },
});
