import { webstore } from "../../vue/helpers/auth-helpers";

AFRAME.registerSystem("navigate-to-scene", {
  transition: function (commands, navigationOptions) {
    this.el.sceneEl.systems["router"].navigate(commands, navigationOptions);
  },
});

AFRAME.registerComponent("navigate-to-scene", {
  schema: { type: "string" },

  events: {
    click: function () {
      this.system.transition(this.data.split("/"));
    },
  },
});

AFRAME.registerComponent("navigate-to-app", {
  schema: { type: "string" },

  events: {
    click: function () {
      if (this.data === "quiz") {
        // this.system.transition(["quiz"]);
        const userToken = webstore.getProperty("token") || "fdf098fcc6";
        // // window.location.href = `http://localhost:5173/?uid=${userToken}`;
        window.location.href = `https://quiz.xr-academy.com?uid=${userToken}`;
      }
    },
  },
});
