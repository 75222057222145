var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showLoader
      ? _c(
          "div",
          {
            staticClass: "content-loader",
            class: { blur_backdrop: _vm.showLoader },
          },
          [
            _c("img", {
              staticClass: "spinner",
              attrs: { src: "images/loader/loader.svg", alt: "" },
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Please wait a moment...")]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "winners_modal_section",
        class: { blur_backdrop: _vm.isModalOpen },
      },
      [
        _c("WinnersModal", {
          attrs: {
            isModalOpen: _vm.isModalOpen,
            status: _vm.status,
            modalText: _vm.modalText,
            onModalClose: _vm.onModalClose,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { attrs: { id: "winners" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c("img", { attrs: { src: "images/ui/poster.png" } }),
      _vm._v(" "),
      _c("div", { staticClass: "form_section" }, [
        _c("h1", [_vm._v("Check if You're a Winner")]),
        _vm._v(" "),
        _c("p", [_vm._v("Enter your mobile number below:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.msisdn,
              expression: "msisdn",
            },
          ],
          attrs: {
            type: "tel",
            name: "msisdn",
            id: "msisdn_entry",
            maxLength: "{10}",
          },
          domProps: { value: _vm.msisdn },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.msisdn = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { disabled: _vm.isButtonDisable },
            on: {
              click: function ($event) {
                return _vm.onSubmit()
              },
            },
          },
          [_vm._v("Check")]
        ),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "link", attrs: { href: "" } }, [
        _vm._v("Terms & Conditions"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "winners_head_text" }, [
      _c("p", { staticClass: "fadeInUp delay_0" }, [
        _vm._v("Your Chance of winning"),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "ZoomIn delay_0_5" }, [_vm._v("R10 000")]),
      _vm._v(" "),
      _c("p", { staticClass: "fadeInUp delay_0_65" }, [
        _vm._v("in OTT Vouchers"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }