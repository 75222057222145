AFRAME.registerSystem("loader", {
  ids: [],
  waitingResolvers: [],

  addIdForLoader: function (id) {
    this.ids.push(id);
    this.updateLoader();
  },

  removeIdForLoader: function (id) {
    this.ids = this.ids.filter((search) => search !== id);
    this.updateLoader();
  },

  clear: function () {
    this.ids = [];
    this.updateLoader();
  },

  isLoading: function () {
    return this.ids.length > 0;
  },

  waitForLoaderToHide: function () {
    if (!this.isLoading()) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.waitingResolvers.push(resolve);
    });
  },

  updateLoader: function () {
    this.el.emit(this.isLoading() ? "showLoader" : "hideLoader");

    if (!this.isLoading()) {
      while (this.waitingResolvers.length > 0) {
        const resolver = this.waitingResolvers.pop();
        resolver();
      }
    }
  },
});

AFRAME.registerComponent("loader", {
  schema: {
    enabled: { type: "boolean", default: false },
  },

  events: {
    materialtextureloaded: function () {
      if (this.currentFrame === "corner") {
        this.rotation = this.rotation <= -270 ? 0 : this.rotation - 90;
        this.el.object3D.rotation.z = THREE.MathUtils.degToRad(this.rotation);
      }

      this.countDown = true;
    },
  },

  init: function () {
    this.currentFrame = "corner";
    this.countDown = true;
    this.rotation = 0;
  },

  update: function () {
    const { enabled } = this.data;
    // this.el.setAttribute('material', 'opacity', enabled ? 1 : 0);
    this.el.setAttribute("animation__opacity", {
      delay: enabled ? 1000 : 0,
      property: "material.opacity",
      to: enabled ? 1 : 0,
    });
  },

  tick: function (time, delta) {
    if (!this.countDown || !this.data.enabled) {
      return;
    }

    this.nextFrame -= delta;

    if (this.nextFrame > 0) {
      return;
    }

    this.currentFrame = this.currentFrame === "corner" ? "center" : "corner";
    this.countDown = false;
    this.el.setAttribute("material", "src", `#ui-loader-${this.currentFrame}`);
    this.nextFrame = 75;
  },
});
