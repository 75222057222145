import { routes } from "../routes";
import { checkAuth } from "../../vue/utils/api.utils";
import {
  redirectToHEURL,
  redirectToLogin,
} from "../../vue/utils/redirect.utils";
import { getState } from "../../vue/utils/aframe.utils";
import { requestDeviceOrientation } from "../utils/device-orientation.utils";
import { getDomain, getUid } from "../../vue/helpers/auth-helpers";
import { STATC_PAGES } from "../../vue/helpers/page-constants";

const defaultTransitionOptions = {
  animate: true,
  updateHistory: true,
};

AFRAME.registerSystem("router", {
  currentScene: null,
  firstScene: true,
  nextRoute: null,
  transitioning: false,

  init: function () {
    this.navigateByHistory = this.navigateByHistory.bind(this);
    this.onHistoryChange = this.onHistoryChange.bind(this);
    this.onPageLoad = this.onPageLoad.bind(this);

    routes.forEach((route) => {
      route.urlCommands = route.url.split("/");
    });

    this.el.addEventListener("renderstart", () => {
      setTimeout(() => this.onPageLoad({ animate: false }), 1000);
    });
    window.addEventListener("popstate", this.onHistoryChange);
  },

  onPageLoad: async function () {
    // Check for Auth and set isAuthorized
    try {
      this.el.emit("showLoader");

      const isAuthorized = await checkAuth();

      this.el.emit("hideLoader");
      if (isAuthorized || STATC_PAGES.indexOf(window.location.pathname) >= 0)
        this.navigateByHistory({ animate: false });
      else {
        const showLogin = new URL(window.location.href).searchParams.get(
          "show-login"
        );
        if (getDomain() === "pe.xr-academy.com" && !showLogin) {
          redirectToHEURL();
        } else {
          redirectToLogin();
        }
      }
    } catch (e) {
      console.log(e);
      this.el.emit("hideLoader");
      redirectToLogin();
    }
  },

  onHistoryChange: function (transitionOptions) {
    const commands = window.location.pathname.split("/").slice(1);
    this.navigateHelper(commands, transitionOptions);
  },

  navigateByHistory: function (transitionOptions) {
    const url = new URL(window.location.href);
    if (url.searchParams.get("uid")) url.searchParams.delete("uid");
    if (url.searchParams.get("msisdn")) url.searchParams.delete("msisdn");
    const commands = window.location.pathname.substring(1).split("/");
    this.navigateHelper(commands, transitionOptions);
  },

  navigate: async function (commands, transitionOptions) {
    const isLogin = getUid();
    transitionOptions = transitionOptions
      ? { ...defaultTransitionOptions, ...transitionOptions }
      : defaultTransitionOptions;

    const pathname = `/${commands.length > 0 ? commands.join("/") : ""}`;
    if (!isLogin && !STATC_PAGES.includes(pathname)) {
      redirectToLogin();
    } else {
      if (pathname !== location.pathname && transitionOptions.updateHistory) {
        window.history.pushState(
          {},
          pathname,
          `${window.location.origin}${pathname}${window.location.search || ""}`
        );
      }
    }
    this.navigateHelper(commands, transitionOptions);
  },

  navigateHelper: async function (commands, transitionOptions) {
    const route = this._getRouteViaCommands(commands);
    if (!route) {
      console.error("Unknown route", commands);
      this.navigate([""], transitionOptions);
      return null;
    }

    this.nextRoute = route;

    if (this.transitioning) {
      return null;
    }

    const {
      canReload,
      requestOrientationPermission,
      scene,
      sceneParameters,
      urlCommands,
    } = this.nextRoute;

    if (this.currentScene === scene && !canReload) {
      return;
    }

    const sceneChange = this.currentScene !== scene;

    if (requestOrientationPermission) {
      requestDeviceOrientation();
    }

    if (this.currentScene) {
      this.firstScene = false;
      this.el.sceneEl.emit("setFirstScene", this.firstScene);
      this.el.sceneEl
        .querySelector(`#scene-${this.currentScene}`)
        .emit("sceneExiting", { commands, sceneChange, scene });
    }

    this.transitioning = true;
    await this.el.systems["transition"].play(true);

    if (this.currentScene) {
      this.el.sceneEl
        .querySelector(`#scene-${this.currentScene}`)
        .emit("sceneExit", { sceneChange });
      this.el.sceneEl.systems["loader"].clear();
    }

    const routeParameters = {};
    urlCommands.forEach((command, index) => {
      if (!command.startsWith(":")) {
        return;
      }

      routeParameters[command.substring(1)] = commands[index];
    });

    this.el.systems["scene"].setScene(scene, {
      ...transitionOptions,
      route,
      routeParameters,
      sceneParameters,
    });

    const { firstScene } = getState();

    if (scene) {
      this.el.sceneEl.querySelector(`#scene-${scene}`).emit("sceneEnter", {
        firstScene,
        routeParameters,
        sceneChange,
        sceneParameters,
      });
    }

    this.currentScene = scene;
    this.firstScene = false;
    this.transitioning = false;
  },

  _getRouteViaCommands: function (commands) {
    return routes.find((route) => {
      const { urlCommands } = route;
      if (commands.length !== urlCommands.length) {
        return;
      }

      return !urlCommands.some(
        (command, index) =>
          !command.startsWith(":") && commands[index] !== command
      );
    });
  },
});
