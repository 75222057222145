<template>
  <div>
    <div
      class="content-loader"
      v-if="showLoader"
      :class="{ blur_backdrop: showLoader }"
    >
      <img src="images/loader/loader.svg" alt="" class="spinner" />
      <h3>Please wait a moment...</h3>
    </div>
    <div class="winners_modal_section" :class="{ blur_backdrop: isModalOpen }">
      <WinnersModal
        :isModalOpen="isModalOpen"
        :status="status"
        :modalText="modalText"
        :onModalClose="onModalClose"
      />
    </div>
    <div id="winners">
      <div class="winners_head_text">
        <p class="fadeInUp delay_0">Your Chance of winning</p>
        <h2 class="ZoomIn delay_0_5">R10 000</h2>
        <p class="fadeInUp delay_0_65">in OTT Vouchers</p>
      </div>
      <img src="images/ui/poster.png" />
      <div class="form_section">
        <h1>Check if You&apos;re a Winner</h1>
        <p>Enter your mobile number below:</p>
        <input
          type="tel"
          name="msisdn"
          id="msisdn_entry"
          maxLength="{10}"
          v-model="msisdn"
        />
        <button @click="onSubmit()" :disabled="isButtonDisable">Check</button>
      </div>
      <a href="" class="link">Terms &amp; Conditions</a>
    </div>
  </div>
</template>

<script>
import { getWinners } from "../utils/winners-api.utils";
import WinnersModal from "../components/winners-modal.component.vue";
import { modalStatusMsg } from "../helpers/page-constants";

export default {
  components: {
    WinnersModal,
  },
  data: () => ({
    msisdn: "",
    isModalOpen: false,
    status: "",
    showLoader: false,
    modalText: "",
  }),

  methods: {
    showMsg(status, modalText) {
      this.isModalOpen = true;
      this.showLoader = false;
      this.status = status;
      this.modalText = modalText;
    },
    onSubmit() {
      this.showLoader = true;
      getWinners(this.msisdn)
        .then((res) => {
          if (res.success) {
            this.showMsg("success", modalStatusMsg.success);
          } else {
            this.showMsg("fail", modalStatusMsg.fail);
          }
        })
        .catch((error) => {
          this.showMsg("fail", modalStatusMsg.error);
        });
    },
    onModalClose() {
      this.isModalOpen = false;
    },
  },
  computed: {
    isButtonDisable() {
      return this.msisdn.length === 0;
    },
  },
};
</script>
<style lang="scss">
.content-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  color: #fff;

  .spinner {
    width: 50px;
    animation: spin 2000ms linear infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
.blur_backdrop {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 17 !important;
}
.winners_modal_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  color: #fff;
  font-family: "Helvetica Neue";
}
#winners {
  background-image: url("images/ui/winners-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  .winners_head_text {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 57px;

    p {
      background: linear-gradient(
        180deg,
        rgba(255, 116, 0, 1) 0%,
        rgba(255, 175, 0, 0.9682466736694678) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 23px;
      margin: 0;
      font-weight: bolder;
    }
    h2 {
      margin: 0;
      font-size: 50px;
      font-weight: bolder;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(178, 179, 178, 1) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  img {
    width: 300px;
    align-items: center;
    margin-top: 1%;
  }
  .form_section {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;

    h1 {
      color: #000;
      font-size: 24px;
      margin: 0;
    }

    p {
      color: #696969;
      margin: 0;
    }
    #msisdn_entry {
      color: #000;
      border: none;
      height: 55px;
      width: 300px;
      outline: none;
      font-size: 22px;
      font-weight: bold;
      margin: 10px auto;
      border-radius: 5px;
      position: relative;
      padding: 10px 10px 10px 65px;
      background-color: #f1f1f1;
      background-size: 50px 41px;
      background-repeat: no-repeat;
      background-position: 10px 50%;
      background-image: url("images/ui/za_flag.pNg");
    }
    button {
      border: none;
      height: 55px;
      width: 300px;
      color: #000000;
      font-size: 20px;
      font-weight: bold;
      border-radius: 10px;
      background-color: #ffaf00;
      cursor: pointer;

      &:disabled {
        color: #5e5e5e;
        background-color: #a0a0a0;
        cursor: default;
      }
    }
  }
  .link {
    color: #ccc;
    font-size: 14px;
    margin: 10px auto;
  }

  @media screen and (max-width: 680px) {
    .winners_modal_section {
      top: 40%;
    }
    .winners_head_text {
      padding-top: 68px;
    }
  }
  @media screen and (max-width: 320px) {
    .form_section {
      h1 {
        font-size: 21px;
      }
      #msisdn_entry {
        width: 260px;
      }
      button {
        width: 260px;
      }
    }
  }
}
.ZoomIn {
  animation-duration: 400ms;
  animation-name: ZoomIn;
  animation-fill-mode: both;
}
.fadeInUp {
  animation-duration: 400ms;
  animation-name: fadeInUp;
  animation-fill-mode: both;
}
@keyframes ZoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.delay_0 {
  animation-delay: 0s;
}

.delay_0_5 {
  animation-delay: 0.5s;
}

.delay_0_65 {
  animation-delay: 0.65s;
}
</style>
